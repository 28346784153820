import React from 'react'
import Layout from "../components/Layout";
import CTABox from "../components/CTABox";
// import Hero from "../components/Hero";

const ProgramLanding = () => {

    return (
        <Layout>
            {/* <Hero image=""/> */}
            
            <CTABox label="Visit Our FAQ Page" url="/">
                <h3>Do You Still Have Questions? Visit Our FAQ Page Or Contact Us</h3>
            </CTABox>

            <CTABox label="Apply Now" url="/">
                <h3>Ready To Apply?</h3>
                <h4>Full Time Programs</h4>

                <p>There is a non-refundable $100 application fee required too submit your application. After applying, an advisor will contact you regarding the additional materials required to complete your application.</p>

                <h4>Part Time &amp; Summer Courses</h4>

                <p>The full registration fee is required to submit your application.</p>
            </CTABox>
            

        </Layout>
    )
}

export default ProgramLanding;