import React from "react";
import "./CTABox.css";

export default function CTABox({children, url, label, image}){
    if (!image)
    {
        return (
            <article className="ctabox">
                {children}
                <a href={url} className="cta-primary">{label}</a>
            </article>
        )
    } else {
        return (
           
            <article className="ctabox nopad">
                <div class="boxcover" style={{backgroundImage: `url("http://api.vanarts.com:3000/assets/${image}")`}}></div>
                <div className="pad">
                    {children}
                    <a href={url} className="cta-primary">{label}</a>
                </div>
            </article>
        )
    }
}